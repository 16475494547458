<template>
  <div class="mt-8">
    <div
      class="bg-white mx-auto p-4 md:rounded md:shadow max-w-xl"
      v-if="campaign"
    >
      <sqr-input-text
        label="Object"
        :value="campaign.subject"
        @change="fieldSet('subject', $event)"
      />
      <sqr-input-textarea
        class="mt-4"
        label="Texte"
        :value="campaign.text"
        @change="fieldSet('text', $event)"
        :rows="10"
      />
      <sqr-input-text
        class="mt-4"
        label="Appel à l'action"
        :value="campaign.ctaLabel"
        @change="fieldSet('ctaLabel', $event)"
      />
      <sqr-input-text
        class="mt-2"
        label="Lien"
        :value="campaign.ctaLink"
        @change="fieldSet('ctaLink', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputTextarea from '@/sqrd-ui/SqrInputTextarea';
export default {
  name: 'CampaignEmail',
  components: { SqrInputText, SqrInputTextarea },
  computed: {
    ...mapState('campaign', { campaign: 'doc' }),
    ...mapState('campaign', ['path'])
  },
  methods: {
    ...mapActions('updater', ['update']),
    fieldSet(field, value) {
      const path = this.path;
      return this.update({ path, doc: { [field]: value } });
    }
  },
  metaInfo() {
    const name = this.name ?? '';
    const title = `Campagne ${name} email`;
    return { title };
  }
};
</script>
